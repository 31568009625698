import { ContentFragment, ContentReference } from '@oneaudi/falcon-tools';

export enum ThemeType {
  light = 'light',
  dark = 'dark',
}

export enum ScreenOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
}

export interface Content {
  theme: ThemeType;
  content: {
    mask: PresetMask | CustomMask;
    showGuide: boolean;
    asset: Asset;
  };
  themeWltp: string;
  wltp: string[];
  additionalText?: string;
}

export interface HeadlessContent extends Partial<ContentFragment> {
  __type: string;
  fields: {
    theme: string;
    content_mask: HeadlessCustomMask | HeadlessPresetMask;
    content_showGuide: boolean;
    content_asset: HeadlessImageAsset | HeadlessVideoAsset;
    themeWltp: string;
    wltp: string[];
    additionalText?: string;
  };
}

export enum MaskType {
  maskPreset = 'maskPreset',
  maskCustom = 'maskCustom',
}

export type Mask = CustomMask | BaseMask;

export interface BaseMask {
  maskType: MaskType;
  text: string;
}

export interface HeadlessCustomMask extends ContentFragment {
  fields: {
    offsetWidth: number;
    offsetHeight: number;
    maskType: string;
    text: string;
  };
}

export interface HeadlessPresetMask extends ContentFragment {
  fields: {
    name?: string;
    zoomMultiplier: number;
    offsetWidth: number;
    offsetHeight: number;
    maskType: string;
    text: string;
  };
}

export interface HeadlessImageAsset extends ContentFragment {
  fields: {
    assetType: string;
    url: ContentReference;
    urlMobile: ContentReference;
    alt: string;
  };
}

export interface HeadlessVideoAsset extends ContentFragment {
  fields: {
    assetType: string;
    sourceDefault: ContentReference;
    sourceMobile: ContentReference;
    loop: boolean;
    playMode: string;
    thresholdValue: number;
    title: string;
    imageFallback: ContentReference;
    imageFallbackMobile: ContentReference;
  };
}
export interface CustomMask extends BaseMask {
  offsetWidth: number;
  offsetHeight: number;
}

export interface PresetMask extends BaseMask {
  name: string;
  zoomMultiplier: number;
  offsetWidth: number;
  offsetHeight: number;
}

export enum PresetTypes {
  GT = 'GT',
  RS = 'RS',
  SONOS = 'SONOS',
  RSQ8 = 'RSQ8',
}

export enum AssetType {
  image = 'image',
  video = 'video',
}

export interface ImageAsset {
  assetType: AssetType;
  url: AssetSource;
  urlMobile: AssetSource;
  alt: string;
}

export enum PlayMode {
  autoPlay = 'autoplay',
  thresholdPlay = 'threshold',
}

export interface VideoAsset {
  assetType: AssetType;
  sourceDefault: AssetSource;
  sourceMobile: AssetSource;
  loop: boolean;
  playMode: PlayMode;
  thresholdValue: number;
  title: string;
  imageFallback: AssetSource;
  imageFallbackMobile: AssetSource;
}

export type Asset = ImageAsset | VideoAsset;

export type AssetSource = {
  assetValue: string;
};

export interface Theme {
  theme: ThemeType.light | ThemeType.dark;
}

export enum BreakPoints {
  // XS = 0,
  S = 375,
  M = 768,
  L = 1024,
  XL = 1440,
  XXL = 1920,
}

export enum FontSizes {
  S = 54,
  M = 120,
  L = 130,
  XL = 190,
  XXL = 220,
}

export interface SsrWindow extends Partial<Window> {
  innerWidth: number;
  innerHeight: number;
}

export type UseWindow = Window | SsrWindow;
