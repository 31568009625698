import { useState, useEffect } from 'react';
import { useTheme } from './use-theme';
import { useViewbox } from './use-viewbox';

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(0);
  const { breakpoints } = useTheme();
  const { width } = useViewbox();

  const breakpointSet = [
    breakpoints.s,
    breakpoints.m,
    breakpoints.l,
    breakpoints.xl,
    breakpoints.xxl,
  ];

  useEffect(() => {
    if (width && width !== 0) {
      setBreakpoint(breakpointSet.find((max) => width <= max) || breakpoints.xxl);
    }
  }, [width]);

  return breakpoint;
};
