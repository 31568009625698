import { UseWindow } from '../types';

export const ssrWindow = {
  innerWidth: 1920,
  innerHeight: 1080,
  outerWidth: 1920,
  outerHeight: 1080,
};

export const getWindow = (): UseWindow => {
  const hasWindow = typeof window !== 'undefined';

  return hasWindow ? window : ssrWindow;
};
