/* eslint-disable consistent-return */
import * as React from 'react';

type UseIntersectionProps = {
  node: React.RefObject<HTMLElement>;
  root?: null | HTMLElement;
  rootMargin?: string;
  threshold?: number | number[];
  once?: boolean;
  callback?: (entry: IntersectionObserverEntry) => void;
};

export const useIntersection = ({
  node,
  root = null,
  rootMargin,
  threshold = 0,
  once = false,
  callback,
}: UseIntersectionProps): IntersectionObserverEntry | undefined => {
  const [sequenceEntry, updateSequenceEntry] = React.useState<IntersectionObserverEntry>();
  const isClient = typeof window === 'object';

  const sequenceObserver = isClient
    ? React.useRef(
        new window.IntersectionObserver(
          ([entry], observer) => {
            if (callback) callback(entry);
            else updateSequenceEntry(entry);
            if (once && entry.isIntersecting) {
              observer.disconnect();
            }
          },
          {
            root,
            rootMargin,
            threshold,
          },
        ),
      )
    : null;

  React.useEffect(() => {
    if (sequenceObserver) {
      const { current: currentObserver } = sequenceObserver;
      currentObserver.disconnect();
      if (node.current) {
        currentObserver.observe(node.current);
      }
      return () => currentObserver.disconnect();
    }
    return (): void => {
      // noop
    };
  }, [node]);
  return sequenceEntry;
};
