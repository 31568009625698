import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { TokenizedTheme } from '@audi/audi-ui-react';
import { getPresetFromCustomInput, getPresetFromString } from '../../presets';
import { hexToRGBa } from '../../utils/hex-to-rgba';
import { useCanvasMediaScroll } from '../../utils/use-canvas-media-scroll';
import { useViewbox } from '../../utils/use-viewbox';
import { ScrollImagesContainer } from './scroll-images-container';
import { CustomMask, Mask, MaskType, PresetMask } from '../../types';

const ScrollCanvas = styled.canvas<{ width: number; height: number }>`
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
`;

export interface CanvasProps {
  children: ReactNode;
  position: number;
  mask: Mask;
  showGuide: boolean;
  theme: TokenizedTheme;
}

// eslint-disable-next-line react/function-component-definition
export const Canvas: React.FC<CanvasProps> = ({ children, position, mask, showGuide, theme }) => {
  const ref = React.useRef<HTMLCanvasElement>(null);

  const { width, height } = useViewbox();

  const maskConfig: PresetMask =
    mask.maskType === MaskType.maskCustom
      ? getPresetFromCustomInput(mask as CustomMask)
      : getPresetFromString(mask.text.replace(/\s/g, ''));

  useCanvasMediaScroll({
    color: hexToRGBa(theme?.colors.ui.inverted, 1),
    font: theme?.typography?.display1?.xxl?.fontFamily || 'arial',
    mask: maskConfig,
    position,
    ref,
    showGuide,
  });

  return (
    <ScrollImagesContainer>
      {children}
      <ScrollCanvas
        ref={ref}
        width={width}
        height={height}
        data-testid="masktext"
        title={mask.text}
      />
    </ScrollImagesContainer>
  );
};
