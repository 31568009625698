import { useEffect, useState, useContext, DependencyList } from 'react';
import { Context } from '../context';
import { Content } from '../types';
import { mapHeadlessContent } from './mapHeadlessContent';

export function useContent(dependencyList: DependencyList = []) {
  const { contentService, renderModeService } = useContext(Context);
  const initialContent: Content = contentService?.getContent ? contentService.getContent() : {};
  const content = mapHeadlessContent(initialContent, renderModeService?.getRenderMode?.() || 0);

  const [store, setStore] = useState<Content>(content);

  useEffect(() => {
    if (contentService?.onContentChange) {
      contentService.onContentChange(() => {
        setStore(
          mapHeadlessContent(contentService.getContent(), renderModeService.getRenderMode()),
        );
      });
    }
  }, dependencyList);

  return store;
}
