import { audiDarkTheme, audiLightTheme } from '@audi/audi-ui-react';
import { Logger } from '@feature-hub/logger';
import {
  cleanupEmissionConsumptionMarkup,
  FootnoteReference,
  getConsumptionsAndEmissionsWithIdentifiers,
  renderTextWithFootnotesReferencesV2,
} from '@oneaudi/feature-app-utils';
import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { createElement, ReactNode } from 'react';
import { I18nMessages, InitialState, WltpProps } from '../FeatureAppTypes';
import { fetchI18nMessages } from '../i18n';
import { Content, ThemeType } from '../types';

interface SerializedWltpProps {
  formattedConsumption: (string | Record<string, unknown>)[] | undefined;
  formattedEmission: (string | Record<string, unknown>)[] | undefined;
  formattedCo2Class: (string | Record<string, unknown>)[] | undefined;
  formattedDischargedConsumption: (string | Record<string, unknown>)[] | undefined;
  formattedDischargedCo2Class: (string | Record<string, unknown>)[] | undefined;
}
let i18nMessages: I18nMessages;

export async function createInitialState(
  content: Content,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  localeService: GfaLocaleServiceV1,
  i18nService: I18NServiceV1,
  logger?: Logger,
): Promise<InitialState> {
  if (!i18nMessages) {
    i18nMessages = await fetchI18nMessages(localeService, i18nService);
  }

  const consumptionAndEmissionValues = await getConsumptionsAndEmissionsWithIdentifiers(
    content.wltp,
    vueFormatterService,
    localeService,
    logger,
  );

  let additionalText: Iterable<ReactNode> | undefined;

  if (content.additionalText) {
    additionalText = renderTextWithFootnotesReferencesV2(content.additionalText);
  }

  const wltp: WltpProps[] = [];

  if (Object.keys(consumptionAndEmissionValues).length > 0) {
    Object.entries(consumptionAndEmissionValues).forEach(([, value]) => {
      const {
        formattedConsumption,
        formattedEmission,
        formattedCo2Class,
        formattedDischargedConsumption,
        formattedDischargedCo2Class,
      } = value;

      if (formattedConsumption && formattedEmission) {
        wltp.push({
          formattedConsumption: renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedConsumption),
          ),
          formattedEmission: renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedEmission),
          ),
          formattedCo2Class: formattedCo2Class
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedCo2Class),
              )
            : undefined,
          formattedDischargedConsumption: formattedDischargedConsumption
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedDischargedConsumption),
              )
            : undefined,
          formattedDischargedCo2Class: formattedDischargedCo2Class
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedDischargedCo2Class),
              )
            : undefined,
        });
      }
    });
  }

  const theme = content.theme === ThemeType.dark ? audiDarkTheme : audiLightTheme;
  const themeWltp = content.themeWltp === ThemeType.dark ? audiDarkTheme : audiLightTheme;

  return { ...content, theme, i18nMessages, additionalText, wltp, themeWltp };
}

const deserializeReactNodeArray = (
  deserializedProperty?: string | (string | Record<string, unknown>)[],
): undefined | string | ReactNode[] => {
  // if it's undefined or a string it doesn't contain any footnotes -> nothing to do
  if (!deserializedProperty || typeof deserializedProperty === 'string') {
    return deserializedProperty;
  }
  return deserializedProperty.map((serializedReactNode) => {
    if (typeof serializedReactNode === 'string') {
      return serializedReactNode;
    }
    // if it's not a string it has to be a <Footnote Reference/> react component
    return createElement(FootnoteReference, serializedReactNode.props as undefined);
  });
};

const deserializeReactNodeArrayInWltpData = (wltpData: SerializedWltpProps[]) => {
  return wltpData.map(
    ({
      formattedConsumption,
      formattedEmission,
      formattedCo2Class,
      formattedDischargedConsumption,
      formattedDischargedCo2Class,
    }) => {
      return {
        formattedConsumption: deserializeReactNodeArray(formattedConsumption),
        formattedEmission: deserializeReactNodeArray(formattedEmission),
        formattedCo2Class: deserializeReactNodeArray(formattedCo2Class),
        formattedDischargedConsumption: deserializeReactNodeArray(formattedDischargedConsumption),
        formattedDischargedCo2Class: deserializeReactNodeArray(formattedDischargedCo2Class),
      };
    },
  );
};
/**
 * /**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-svg-scroll-zoom-module
 * Internal](https://github.com/oneaudi/fa-svg-scroll-zoom-module). Update all of your dependencies now!
 * Helper function to deserialize the state of the feature app. It converts
 * serialized ReactNodeArray Entries that aren't string to FootnoteReference components
 * @param state
 */
export function deserializeState(serialized: string): InitialState {
  const props = JSON.parse(serialized);

  return {
    ...props,
    additionalText: deserializeReactNodeArray(props.additionalText),
    wltp: deserializeReactNodeArrayInWltpData(props.wltp),
  };
}
