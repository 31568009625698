import React from 'react';
import { Context } from './index';

export interface TrackingManager {
  loaded(): void;
  impression(isFallback: boolean, assetType: string): void;
  reachEndOfModule(assetType: string, position: number): boolean;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingEvent {
  const { host, pathname } = document.location;
  const defaultAttributes = {
    componentName: 'scroll zoom module',
    currentURL: `${host}${pathname}`,
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

export function useTrackingManager(): TrackingManager {
  const { trackingService, logger } = React.useContext(Context);

  return React.useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        logger?.debug('Sending tracking event: ', payload);
        trackingService.track(payload);
      }
    }

    return {
      loaded() {
        track('feature_app_ready', 'feature app ready', {
          componentName: 'scroll zoom module',
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        });
      },

      impression(isFallback: boolean, assetType: string) {
        // value = fallback~img | fallback~video | standard~img | standard~video
        track('impression', 'Scroll Zoom Module - impression', {
          componentName: 'scroll zoom module',
          label: '',
          currentURL: window.location.href,
          targetURL: '',
          clickID: '',
          elementName: '',
          value: `${isFallback ? 'fallback' : 'standard'}~${assetType}`,
          pos: '',
        });
      },

      reachEndOfModule(assetType: string, position: number) {
        // only trigger reach end of module if at least 97% have been scrolled
        if (position >= 0.97) {
          // value = standard~img | standard~video
          track('impression', 'Scroll Zoom Module - end of module', {
            componentName: 'scroll zoom module',
            label: '',
            currentURL: window.location.href,
            targetURL: '',
            clickID: '',
            elementName: '',
            value: `standard~${assetType}`,
            pos: '',
          });
          // we tracked
          return true;
        }
        // did not track yet
        return false;
      },
    };
  }, [trackingService]);
}
