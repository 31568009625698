import { useEffect, RefObject, useState } from 'react';
import { useBreakpoint } from './use-breakpoint';
import { useViewbox } from './use-viewbox';
import { isPortrait } from './get-orientation';
import { PresetMask } from '../types';
import { drawCoordinateSystem, drawVanishingPoint } from './canvas-guide';

// Audi default font stretch, size and vertical alignment
const fontStretch = 1.28;
const fontSizeRatio = 5.5;
const fontVerticalAlignRatio = 2.8;
const getFontSize = (boundBoxSize: number) => boundBoxSize / fontSizeRatio;
const getFontVerticalAlign = (boundBoxSize: number) =>
  getFontSize(boundBoxSize) / fontVerticalAlignRatio;

export interface DocumentExperimental extends Document {
  onloadingdone(): void;
  FontFaceSet: {
    onloading: unknown;
  };
}

export interface UseCanvasScrollProps {
  color: string;
  position: number;
  ref: RefObject<HTMLCanvasElement>;
  font: string;
  mask: PresetMask;

  // Displays guidelines and vanishing point coordinates
  showGuide: boolean;
}

// Calculate translation (camera pan)
const translate = (start: number, offset: number, scale: number) => {
  return start - (start + offset * -1 - start) * scale;
};

// Calculate preset offset
const getBoundOffset = (width: number, offset: number) => (width / 100) * offset;

const clearCanvas = (context: CanvasRenderingContext2D, width: number, height: number) => {
  context.setTransform(1, 0, 0, 1, 0, 0);
  context.clearRect(0, 0, width, height);
};

// Set cutout filter for canvas contents
const drawCutoutFilter = (
  context: CanvasRenderingContext2D,
  width: number,
  height: number,
  color: string,
) => {
  context.globalCompositeOperation = 'lighter';
  context.fillRect(0, 0, width, height);
  context.rect(0, 0, width, height);
  context.fillStyle = color;
  context.fill();
  context.globalCompositeOperation = 'destination-out';
};

function addMediaQueryEventListener(
  mediaQuery: MediaQueryList,
  listener: (e: MediaQueryListEvent) => void,
) {
  if (typeof mediaQuery.addEventListener === 'function') {
    mediaQuery.addEventListener('change', listener, { passive: true });
  } else {
    // Fallback for older Browser e.g. Safari < v5
    mediaQuery.addListener(listener);
  }
}

function removeMediaQueryEventListener(
  mediaQuery: MediaQueryList,
  listener: (e: MediaQueryListEvent) => void,
) {
  if (typeof mediaQuery.removeEventListener === 'function') {
    mediaQuery.removeEventListener('change', listener);
  } else {
    // Fallback for older Browser e.g. Safari < v5
    mediaQuery.removeListener(listener);
  }
}

export const useCanvasMediaScroll = ({
  position,
  ref,
  color,
  font,
  mask,
  showGuide,
}: UseCanvasScrollProps): void => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [disableAnimation, setDisableAnimation] = useState(false);
  const [scale, setScale] = useState(0);

  const { width, height, orientation } = useViewbox();
  const breakpoint = useBreakpoint();

  function updateDisableAnimation(mediaQuery: MediaQueryList | MediaQueryListEvent) {
    setDisableAnimation(mediaQuery.matches);
  }

  // Reload canvas when correct font has been loaded.
  useEffect(() => {
    // Experimental feature https://caniuse.com/?search=onloadingdone
    (document as DocumentExperimental).fonts.onloadingdone = () => setFontsLoaded(true);
  }, []);

  // reduce motion
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    addMediaQueryEventListener(mediaQuery, updateDisableAnimation);
    updateDisableAnimation(mediaQuery);

    return () => {
      removeMediaQueryEventListener(mediaQuery, updateDisableAnimation);
    };
  }, []);

  useEffect(() => {
    if (!disableAnimation) {
      // adjusting the scaling factor based on the scroll position
      const zoomRatio = isPortrait() ? height / 100 : width / 100;
      // Scale up the zoom multiplier
      const positionEaseIn = position ** 4;
      setScale(positionEaseIn * zoomRatio * mask.zoomMultiplier);
    } else {
      setScale(0);
    }
  }, [position, disableAnimation]);

  useEffect(() => {
    const canvas = ref.current;
    const context = canvas?.getContext('2d');
    const { text: canvasText, offsetWidth, offsetHeight } = mask;

    if (context) {
      clearCanvas(context, width, height);

      // Setup background + cutout filter
      if (!showGuide) {
        drawCutoutFilter(context, width, height, color);
      }

      // Shortest side for the bounding box
      // Bounding box: to restrict cutout content within visible area on all screen ratios.
      const boundBoxSize = width >= height ? height : width;

      // Pan & Zoom + add default audi font stretch to width
      /**
       * @param Horizontal scaling
       * @param Horizontal skewing
       * @param Vertical skewing
       * @param Vertical scaling
       * @param Horizontal moving
       * @param Vertical moving
       */
      context.setTransform(
        fontStretch + scale,
        0,
        0,
        1 + scale,
        translate(width / 2, getBoundOffset(boundBoxSize, offsetWidth * -1), scale),
        translate(height / 2, getBoundOffset(boundBoxSize, offsetHeight), scale),
      );

      // Draw the cutout text
      if (showGuide) {
        // Define special cutout color when guide is enabled
        context.globalCompositeOperation = 'source-over';
        context.fillStyle = 'lime';
      }

      context.font = `normal normal bold ${getFontSize(boundBoxSize)}px ${font}`;
      context.textAlign = 'center';
      context.fillText(canvasText, 0, getFontVerticalAlign(boundBoxSize));

      // Show on top of text
      if (showGuide) {
        drawCoordinateSystem(context, boundBoxSize, offsetWidth, offsetHeight);
        drawVanishingPoint(context, boundBoxSize, offsetWidth, offsetHeight);
      }
    }
  }, [mask, scale, ref, width, height, fontsLoaded, breakpoint, orientation, showGuide, color]);
};
