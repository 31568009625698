import { MaskType, CustomMask, PresetMask, PresetTypes } from './types';

export const ZOOM_MULTIPLIER = 75;

export interface Presets {
  [PresetTypes.GT]: PresetMask;
  [PresetTypes.RS]: PresetMask;
  [PresetTypes.SONOS]: PresetMask;
  [PresetTypes.RSQ8]: PresetMask;
}

export const presets: Presets = {
  [PresetTypes.GT]: {
    maskType: MaskType.maskPreset,
    name: PresetTypes.GT,
    text: PresetTypes.GT,
    zoomMultiplier: ZOOM_MULTIPLIER,
    offsetWidth: -1,
    offsetHeight: -2.3,
  },
  [PresetTypes.RS]: {
    maskType: MaskType.maskPreset,
    name: PresetTypes.RS,
    text: PresetTypes.RS,
    zoomMultiplier: ZOOM_MULTIPLIER,
    offsetWidth: 6,
    offsetHeight: 0,
  },
  [PresetTypes.SONOS]: {
    maskType: MaskType.maskPreset,
    name: PresetTypes.SONOS,
    text: PresetTypes.SONOS,
    zoomMultiplier: ZOOM_MULTIPLIER,
    offsetWidth: 0,
    offsetHeight: 0,
  },
  [PresetTypes.RSQ8]: {
    maskType: MaskType.maskPreset,
    name: PresetTypes.RSQ8,
    text: 'RS Q8',
    zoomMultiplier: ZOOM_MULTIPLIER,
    offsetWidth: 3.5,
    offsetHeight: 0,
  },
};

export const getPresetFromString = (text: string): PresetMask => {
  const preset =
    [PresetTypes.GT, PresetTypes.RS, PresetTypes.SONOS, PresetTypes.RSQ8].find(
      (item) => item === text,
    ) || PresetTypes.GT;

  return presets[preset];
};

export const getPresetFromCustomInput = (maskingData: CustomMask | null = null): PresetMask => {
  return {
    maskType: MaskType.maskCustom,
    name: 'Custom',
    text:
      // eslint-disable-next-line no-nested-ternary
      maskingData && maskingData.text
        ? maskingData.text.toUpperCase()
        : presets[PresetTypes.GT].text
          ? presets[PresetTypes.GT].text
          : PresetTypes.GT,
    zoomMultiplier: ZOOM_MULTIPLIER,
    offsetWidth: maskingData ? maskingData.offsetWidth : presets[PresetTypes.GT].offsetWidth,

    offsetHeight: maskingData ? maskingData.offsetHeight : presets[PresetTypes.GT].offsetHeight,
  };
};
