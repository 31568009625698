// Show bounding box on screen

export const drawCoordinateSystem = (
  context: CanvasRenderingContext2D,
  width: number,
  offsetWidth: number,
  offsetHeight: number,
) => {
  const top = (width / 2) * -1;
  const left = (width / 2) * -1;

  // Set colors
  context.fillStyle = '#fff';
  context.strokeStyle = '#fff';

  // Start new lines
  context.beginPath();

  // Common marker variables related to container width
  const marker = Math.round(width / 100);
  // Set multiplier for the marker distance
  const distanceMarker = marker * 5;

  // x-axis
  context.moveTo(left, 0);
  context.lineTo(width, 0);

  // x-axis markers
  for (let x = 0; x < width; x += marker) {
    // draw positive x-axis area
    context.moveTo(x, -marker);
    context.lineTo(x, marker);
    // draw negative x-axis area
    context.moveTo(-x, -marker);
    context.lineTo(-x, marker);
    // draw numbers to every marker
    if (x % distanceMarker === 0 && x !== 0) {
      context.font = 'normal normal bold 10px sans-serif';
      context.fillText(`${x / marker}`, x, -20);
      context.fillText(`-${x / marker}`, -x, -20);
      context.textAlign = 'center';
    }
  }

  // y-axis
  context.moveTo(0, top);
  context.lineTo(0, width);

  // y-axis markers
  for (let y = 0; y < width; y += marker) {
    // draw positive y-axis area
    context.moveTo(-marker, -y);
    context.lineTo(marker, -y);
    // draw negative y-axis area
    context.moveTo(-marker, y);
    context.lineTo(marker, y);
    // draw numbers to every marker
    if (y % distanceMarker === 0 && y !== 0) {
      context.font = 'normal normal bold 10px sans-serif';
      context.fillText(`-${y / marker}`, -23, y + 3);
      context.fillText(`${y / marker}`, -23, -y + 3);
      context.textAlign = 'center';
    }
  }

  // Draw lines
  context.stroke();

  // Label text
  context.font = 'normal normal bold 14px sans-serif';
  context.fillText(`Vertical Coordinate (y set on ${offsetHeight})`, -140, top + 40);
  context.fillText(`Horizontal Coordinate (x set on ${offsetWidth})`, left + width / 4, -40);
  context.textAlign = 'left';
};

// Show vanishing point coords on canvas
export const drawVanishingPoint = (
  context: CanvasRenderingContext2D,
  width: number,
  offsetWidth: number,
  offsetHeight: number,
) => {
  const top = (width / 2) * -1;
  const left = (width / 2) * -1;

  // Set colors
  context.fillStyle = 'red';
  context.strokeStyle = 'red';

  // Start new lines
  context.beginPath();

  // y-axis
  context.moveTo((width / 100) * offsetWidth, top);
  context.lineTo((width / 100) * offsetWidth, width);

  // x-axis
  context.moveTo(left, -(width / 100) * offsetHeight);
  context.lineTo(width, -(width / 100) * offsetHeight);

  // Draw path
  context.stroke();
};
