import { useContext } from 'react';
import { ScreenOrientation } from '../types';
import { CreateBoxContext } from './create-context-box';
import { getOrientation } from './get-orientation';

export interface ViewBox {
  width: number;
  height: number;
  orientation: ScreenOrientation;
}

export function useViewbox(): ViewBox {
  const { width, height } = useContext(CreateBoxContext);
  const orientation = getOrientation();

  return {
    width,
    height,
    orientation,
  };
}
