import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Layout, LayoutItem, Text, TokenizedTheme } from '@audi/audi-ui-react';
import { AdditionalText, WltpProps } from '../../FeatureAppTypes';
import { isDarkTheme } from '../../utils/use-theme';

const WltpWrapper = styled.span`
  display: block;
`;

const WltpSpan = styled.span`
  color: ${({ theme }) =>
    isDarkTheme(theme) ? theme.colors.base.brand.white : theme.colors.base.brand.black};
  > sup {
    line-height: 0%;
  }
`;

WltpSpan.displayName = 'WltpSpan';

const LegalArea = styled.div`
  background-color: ${({ theme }) =>
    isDarkTheme(theme) ? theme.colors.base.brand.black : theme.colors.base.brand.white};
`;

interface LegalComponentProps {
  theme: TokenizedTheme;
  wltp: WltpProps[];
  // eslint-disable-next-line react/require-default-props
  additionalText?: AdditionalText;
}

// eslint-disable-next-line react/function-component-definition
const WltpValuesComponent: React.FC<WltpProps> = ({
  formattedConsumption,
  formattedEmission,
  formattedCo2Class,
  formattedDischargedCo2Class,
  formattedDischargedConsumption,
}: WltpProps) => {
  return (
    <WltpWrapper data-testid="wltp">
      <WltpSpan data-testid="consumption">{formattedConsumption}</WltpSpan>
      <WltpSpan data-testid="emission">; {formattedEmission}</WltpSpan>
      {formattedCo2Class && <WltpSpan data-testid="co2Class">; {formattedCo2Class}</WltpSpan>}
      {formattedDischargedConsumption && (
        <WltpSpan data-testid="dischargedConsumption">; {formattedDischargedConsumption}</WltpSpan>
      )}
      {formattedDischargedCo2Class && (
        <WltpSpan data-testid="dischargedCo2Class">; {formattedDischargedCo2Class}</WltpSpan>
      )}
    </WltpWrapper>
  );
};

// eslint-disable-next-line react/function-component-definition
export const LegalComponent: React.FC<LegalComponentProps> = ({ theme, wltp, additionalText }) => {
  return (
    <ThemeProvider theme={theme}>
      {wltp.length > 0 || additionalText ? (
        <LegalArea data-testid="wltp">
          <Layout
            spaceInsetStackStart="l"
            spaceInsetStackEnd="xxxl"
            spaceInlineStart="pageMargin"
            spaceInlineEnd="pageMargin"
          >
            <LayoutItem basis={{ xs: '86%', m: '92%' }} shrink="0">
              <Text variant="copy2">
                {wltp &&
                  wltp.map((data, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <WltpValuesComponent {...data} key={index} />
                  ))}
                {additionalText && (
                  <WltpSpan data-testid="additional-legal-text">{additionalText}</WltpSpan>
                )}
              </Text>
            </LayoutItem>
          </Layout>
        </LegalArea>
      ) : undefined}
    </ThemeProvider>
  );
};
