import React, { useContext } from 'react';
import { Context } from '../../context';

import { ImageAsset } from '../../types';
import { useViewbox } from '../../utils/use-viewbox';
import { AssetHelper } from '../../helper/assetHelper';
import { useTheme } from '../../utils/use-theme';
import { StyledImage, StyledPicture } from './imageCommon';

export interface ImageProps {
  testId: string;
  asset: ImageAsset;
}

export const Image = ({ testId, asset }: ImageProps) => {
  const { logger } = useContext(Context);
  const { url: sourceDefault, urlMobile: sourceMobile, alt } = asset;
  const { breakpoints } = useTheme();
  const url = `${AssetHelper.getImageUrl(sourceDefault?.assetValue, logger)}?width=`;
  const urlMobile = `${AssetHelper.getImageUrl(sourceMobile?.assetValue, logger)}?width=`;
  const { height, width } = useViewbox();

  return (
    <StyledPicture imgWidth={width}>
      <source
        srcSet={`${urlMobile}${breakpoints.m} 2x, ${urlMobile}${breakpoints.s}`}
        media={`(max-width: ${breakpoints.s - 1}px)`}
      />
      <source
        srcSet={`${urlMobile}${breakpoints.l} 2x, ${urlMobile}${breakpoints.m}`}
        media={`(max-width: ${breakpoints.m - 1}px) and (orientation: portrait)`}
      />
      <source
        srcSet={`${url}${breakpoints.l} 2x, ${url}${breakpoints.m}`}
        media={`(max-width: ${breakpoints.m}px)`}
      />
      <source
        srcSet={`${url}${breakpoints.xxl} 2x, ${url}${breakpoints.l}`}
        media={`(max-width: ${breakpoints.l}px)`}
      />
      <source
        srcSet={`${url}${breakpoints.xxl} 2x, ${url}${breakpoints.xxl}`}
        media={`(min-width: ${breakpoints.l}px)`}
      />
      <StyledImage
        imgWidth={width}
        imgHeight={height}
        src={`${url}${breakpoints.s}`}
        alt={alt}
        data-testid={testId}
      />
    </StyledPicture>
  );
};
