import { useState, RefObject, useEffect } from 'react';
import { useIntersection } from './use-intersection';
import { useScrollEffect } from './use-scroll-effect';
import { useViewbox } from './use-viewbox';

export interface UseBoxScrollProps {
  outerRef: RefObject<HTMLDivElement>;
  innerRef: RefObject<HTMLDivElement>;
}

export const useBoxScroll = ({ outerRef, innerRef }: UseBoxScrollProps) => {
  const { width: vbWidth, height: vbHeight, orientation } = useViewbox();
  const [offsetTop, setOffsetTop] = useState(0);
  const [height, setHeight] = useState({ hasHeight: false, height: [0, 0] });
  const [position, setPosition] = useState(0);

  const entry = useIntersection({
    node: outerRef,
    // TODO: Move to prop
    rootMargin: '50px 50px 50px 50px',
  });

  useScrollEffect(() => {
    if (innerRef?.current && entry?.isIntersecting) {
      setOffsetTop(innerRef.current.offsetTop);
    }
    // TODO: Simplify code, we dont need so many triggers
  }, [entry, vbWidth, vbHeight, orientation]);

  useEffect(() => {
    if (innerRef?.current && outerRef?.current) {
      setHeight({
        hasHeight: true,
        height: [innerRef.current.clientHeight, outerRef.current.clientHeight],
      });
    } else {
      setHeight({
        hasHeight: false,
        height: [0, 0],
      });
    }
  }, [innerRef, outerRef, vbWidth, vbHeight, orientation]);

  useEffect(() => {
    const rawPosition = height.hasHeight ? offsetTop / (height.height[1] - height.height[0]) : 0;
    const roundedPosition = Math.round(rawPosition * 1000) / 1000;

    setPosition(roundedPosition);
  }, [height, offsetTop]);

  return {
    offsetTop,
    height: height.hasHeight ? height.height : undefined,
    position,
  };
};
