import { ScreenOrientation } from '../types';
import { getWindow } from './get-window';

export const getOrientation = (): ScreenOrientation => {
  if (typeof window !== 'object' || !window.matchMedia) {
    const { innerHeight = 0, innerWidth = 0 } = getWindow();

    return innerWidth >= innerHeight ? ScreenOrientation.landscape : ScreenOrientation.portrait;
  }

  return window.matchMedia('(orientation: portrait)').matches
    ? ScreenOrientation.portrait
    : ScreenOrientation.landscape;
};

export const isLandscape = () => {
  return getOrientation() === ScreenOrientation.landscape;
};

export const isPortrait = () => {
  return getOrientation() === ScreenOrientation.portrait;
};
