/* eslint-disable no-unused-expressions */
import { RefObject, useEffect, useState } from 'react';
import { BoxContextValue } from './create-context-box';

const getBoxContext = <T extends HTMLElement>(ref: RefObject<T>): BoxContextValue => {
  if (ref.current && ref.current?.getBoundingClientRect) {
    const { top, left, width, height } = ref.current.getBoundingClientRect();
    return { top, left, width, height };
  }

  return { top: 0, left: 0, width: 0, height: 0 };
};
export const useClientRect = <T extends HTMLElement>(ref: RefObject<T>) => {
  const { top, left, width, height } = getBoxContext(ref);
  const [viewBox, setBox] = useState<BoxContextValue>({
    top,
    left,
    width,
    height,
  });

  useEffect(() => {
    if (ref.current) setBox(getBoxContext(ref));
  }, []);

  useEffect(() => {
    const handleEvent = () => {
      if (ref.current) setBox(getBoxContext(ref));
    };

    window.addEventListener('resize', handleEvent);
    window.addEventListener('orientationchange', handleEvent);

    return () => {
      window.removeEventListener('resize', handleEvent);
      window.removeEventListener('orientationchange', handleEvent);
    };
  }, []);

  return viewBox;
};
