import * as React from 'react';

export type BoxContextValue = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export const CreateBoxContext = React.createContext<BoxContextValue>({
  top: 0,
  left: 0,
  width: 0,
  height: 0,
});
