import { audiDarkTheme, audiLightTheme, TokenizedTheme, Theme } from '@audi/audi-ui-react';
import { ThemeType } from '../types';
import { useContent } from './use-content';

export const useTheme = (): TokenizedTheme => {
  const { theme } = useContent();

  return theme === ThemeType.dark ? audiDarkTheme : audiLightTheme;
};

export const isDarkTheme = (theme: Theme): boolean => theme.name === 'Audi Dark Theme';
