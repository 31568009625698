import styled from 'styled-components';

export const StyledPicture = styled.picture<{ imgWidth: number }>`
  width: ${({ imgWidth }) => (imgWidth ? `${imgWidth}px` : '100vw')};
`;

export const StyledImage = styled.img<{ imgWidth: number; imgHeight: number }>`
  width: ${({ imgWidth }) => (imgWidth ? `${imgWidth}px` : '100%')};
  height: ${({ imgHeight }) => (imgHeight ? `${imgHeight}px` : '100vh')};
  object-fit: cover;
`;
