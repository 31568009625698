import { Logger } from '@feature-hub/core';

export interface ContentAudiImage {
  image: [
    {
      imageFormats: {
        'web-largest': string;
      };
    },
  ];
}

export interface ContentAudiVideo {
  video: [
    {
      sourceFormats: {
        // eslint-disable-next-line camelcase
        content_1080p: string;
      };
    },
  ];
}

export class AssetHelper {
  private static getAssetDataObject(jsonObj: unknown) {
    if (typeof jsonObj === 'string') {
      return JSON.parse(jsonObj);
    }
    return jsonObj;
  }

  private static replaceHost(url: string): string {
    if (url.indexOf('audivmstest-a.akamaihd.net') !== -1) {
      // pre-live host
      return url.replace(
        'audivmstest-a.akamaihd.net/brand/stage/brandportal/',
        'pre-www.audi.de/content-audi-assets/',
      );
    }
    if (url.indexOf('audivms-a.akamaihd.net') !== -1) {
      // live host
      return url.replace(
        'audivms-a.akamaihd.net/brand/stage/brandportal/',
        'www.audi.de/content-audi-assets/',
      );
    }
    return url;
  }

  static getImageUrl(jsonObj: ContentAudiImage | string | undefined, logger?: Logger): string {
    if (!jsonObj) return ''; // no image if no data is provided

    // JSON from content.audi => extract image URL
    try {
      // check for normal URL
      if (typeof jsonObj === 'string' && !jsonObj.startsWith('{')) {
        // standard URL
        return jsonObj;
      }
      // we have a json object
      const assetData = AssetHelper.getAssetDataObject(jsonObj);

      // check if it is an image or video
      if (Object.prototype.hasOwnProperty.call(assetData, 'image')) {
        const { imageFormats } = assetData.image[0];
        const { 'web-largest': webLargest } = imageFormats;
        if (!webLargest) {
          throw new Error('web-largest not found in imageFormats');
        }
        return AssetHelper.replaceHost(webLargest);
      }
      // no video data
      logger?.error(`Error retrieving image or video URL from JSON.`, jsonObj);
      return ''; // no image or video URL
    } catch (error) {
      logger?.error(`Error getting imageFormats["web-largest"] from image[0].`, jsonObj, error);
      return ''; // no image in case of an error
    }
  }

  static getVideoUrl(jsonObj: ContentAudiVideo | string | undefined, logger?: Logger): string {
    if (!jsonObj) return ''; // no video if no data is provided

    // JSON from content.audi => extract video URL
    try {
      // check for normal URL
      if (typeof jsonObj === 'string' && !jsonObj.startsWith('{')) {
        // standard URL
        return jsonObj;
      }
      // we have a json object
      const assetData = AssetHelper.getAssetDataObject(jsonObj);

      // check if it is an image or video
      if (Object.prototype.hasOwnProperty.call(assetData, 'video')) {
        const { sourceFormats } = assetData.video[0];
        const { content_1080p: fullHdUrl } = sourceFormats;
        if (!fullHdUrl) {
          throw new Error('content_1080p not found in imageFormats');
        }
        return fullHdUrl;
      }
      // no video data
      logger?.error(`Error retrieving video URL from JSON.`, jsonObj);
      return ''; // no image or video URL
    } catch (error) {
      logger?.error(`Error getting sourceFormats["content_1080p"] from video[0].`, jsonObj, error);
      return ''; // no video URL in case of an error
    }
  }
}
